/*!
*   WS Starter
*   Designed by LRS Web Solutions (http://lrswebsolutions.com)
*   Visit http://lrswebsolutions.com
*/

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
}

form {
  padding: 0;
}

a {
  color: blue;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:visited {
    color: blue;
  }

  &:hover,
  &:focus {
    color: purple;
    text-decoration: underline;
  }
}

body.authenticate {
  background: #262626;

  form {
    .login {
      background: #f1f1f1;

      .logo {
        padding: 45px 0;

        &::after {
          clear: both;
          content: "";
          display: table;
        }

        img {
          display: block;
          max-width: 320px;
          max-height: 80px;
          margin: 0 auto;

          @media only screen and (min-width: 480px) {
            max-height: 120px;
          }
        }

        h1 {
          background: none;
          height: auto;
        }
      }

      .login-form-container {
        padding: 38px 0;
        background: #3b3b3b;
      }

      .form-cta a {
        color: #ddbf5b;

        &:hover,
        &:focus {
          color: white;
        }
      }

      label {
        display: block;
        margin: 0;
        font-size: 14px;
        color: #777;
        cursor: pointer;
        -webkit-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
    }

    .card {
      max-width: 300px;
      padding: 32px 26px 24px;
      margin: 20px auto;
      overflow: hidden;
      font-weight: 400;
      border: none;
      background: transparent;

      @media only screen and (min-width: 480px) {
        max-width: 360px;
      }

      h2 {
        margin-top: 0;
      }

      p {
        margin-bottom: 20px;
      }
    }

    input[type='email'],
    input[type='password'],
    input[type='text'] {
      display: block;
      width: 100%;
      height: 40px;
      padding: 7px 8px;
      margin: 0;
      font-family: 'Open Sans', Arial, sans-serif;
      font-size: 18px;
      font-weight: 100;
      line-height: 1;
      color: #555;
      border: 1px solid #ccc;
      outline: none;
      -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    input[type='email']::-webkit-input-placeholder,
    input[type='password']::-webkit-input-placeholder,
    input[type='text']::-webkit-input-placeholder {
      font-weight: 100;
      color: #aaa;
    }

    input[type='email']::-moz-placeholder,
    input[type='password']::-moz-placeholder,
    input[type='text']::-moz-placeholder {
      font-weight: 100;
      color: #aaa;
    }

    input[type='email']:-moz-placeholder,
    input[type='password']:-moz-placeholder,
    input[type='text']:-moz-placeholder {
      font-weight: 100;
      color: #aaa;
    }

    input[type='email']:-ms-input-placeholder,
    input[type='password']:-ms-input-placeholder,
    input[type='text']:-ms-input-placeholder {
      font-weight: 100;
      color: #aaa;
    }

    input[type='email']:focus,
    input[type='password']:focus,
    input[type='text']:focus {
      border-color: #999 !important;
      box-shadow: rgba(0, 0, 0, 0.09804) 0 1px 2px 0;
    }

    .form-group {
      display: block;
      margin-bottom: 16px;
    }

    a {
      color: #777;
      text-decoration: none;
      transition: all 0.2s ease;

      &:hover,
      &:focus {
        color: #428bca;
      }
    }

    input[type='submit'] {
      display: block;
      width: 100%;
      height: 40px;
      margin: 0;
      font-family: 'Open Sans', Arial, sans-serif;
      font-size: 18px;
      color: #fff;
      text-align: center;
      text-decoration: none;
      white-space: no-wrap;
      vertical-align: baseline;
      cursor: pointer;
      border: 1px solid #d3a928;
      background: #d3a928;
      box-sizing: border-box;
      transition: all 0.4s ease;

      &:hover,
      &:focus {
        background: gray;
        border: 1px solid gray;
      }
    }

    .version {
      display: block;
      font-size: 12px;
      text-align: center;
      opacity: 0.6;
      padding: 45px 0;

      @media only screen and (min-width: 840px) {
        bottom: 20px;
      }

      a {
        color: #777;
        text-decoration: none;
      }
    }
  }
}
/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.element-invisible {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

/*!
*   WS Starter
*   Designed by LRS Web Solutions (http://lrswebsolutions.com)
*   Visit http://lrswebsolutions.com
*/
html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
}

form {
  padding: 0;
}

a {
  color: blue;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:visited {
  color: blue;
}
a:hover, a:focus {
  color: purple;
  text-decoration: underline;
}

body.authenticate {
  background: #262626;
}
body.authenticate form .login {
  background: #f1f1f1;
}
body.authenticate form .login .logo {
  padding: 45px 0;
}
body.authenticate form .login .logo::after {
  clear: both;
  content: "";
  display: table;
}
body.authenticate form .login .logo img {
  display: block;
  max-width: 320px;
  max-height: 80px;
  margin: 0 auto;
}
@media only screen and (min-width: 480px) {
  body.authenticate form .login .logo img {
    max-height: 120px;
  }
}
body.authenticate form .login .logo h1 {
  background: none;
  height: auto;
}
body.authenticate form .login .login-form-container {
  padding: 38px 0;
  background: #3b3b3b;
}
body.authenticate form .login .form-cta a {
  color: #ddbf5b;
}
body.authenticate form .login .form-cta a:hover, body.authenticate form .login .form-cta a:focus {
  color: white;
}
body.authenticate form .login label {
  display: block;
  margin: 0;
  font-size: 14px;
  color: #777;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
body.authenticate form .card {
  max-width: 300px;
  padding: 32px 26px 24px;
  margin: 20px auto;
  overflow: hidden;
  font-weight: 400;
  border: none;
  background: transparent;
}
@media only screen and (min-width: 480px) {
  body.authenticate form .card {
    max-width: 360px;
  }
}
body.authenticate form .card h2 {
  margin-top: 0;
}
body.authenticate form .card p {
  margin-bottom: 20px;
}
body.authenticate form input[type=email],
body.authenticate form input[type=password],
body.authenticate form input[type=text] {
  display: block;
  width: 100%;
  height: 40px;
  padding: 7px 8px;
  margin: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px;
  font-weight: 100;
  line-height: 1;
  color: #555;
  border: 1px solid #ccc;
  outline: none;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
body.authenticate form input[type=email]::-webkit-input-placeholder,
body.authenticate form input[type=password]::-webkit-input-placeholder,
body.authenticate form input[type=text]::-webkit-input-placeholder {
  font-weight: 100;
  color: #aaa;
}
body.authenticate form input[type=email]::-moz-placeholder,
body.authenticate form input[type=password]::-moz-placeholder,
body.authenticate form input[type=text]::-moz-placeholder {
  font-weight: 100;
  color: #aaa;
}
body.authenticate form input[type=email]:-moz-placeholder,
body.authenticate form input[type=password]:-moz-placeholder,
body.authenticate form input[type=text]:-moz-placeholder {
  font-weight: 100;
  color: #aaa;
}
body.authenticate form input[type=email]:-ms-input-placeholder,
body.authenticate form input[type=password]:-ms-input-placeholder,
body.authenticate form input[type=text]:-ms-input-placeholder {
  font-weight: 100;
  color: #aaa;
}
body.authenticate form input[type=email]:focus,
body.authenticate form input[type=password]:focus,
body.authenticate form input[type=text]:focus {
  border-color: #999 !important;
  box-shadow: rgba(0, 0, 0, 0.09804) 0 1px 2px 0;
}
body.authenticate form .form-group {
  display: block;
  margin-bottom: 16px;
}
body.authenticate form a {
  color: #777;
  text-decoration: none;
  transition: all 0.2s ease;
}
body.authenticate form a:hover, body.authenticate form a:focus {
  color: #428bca;
}
body.authenticate form input[type=submit] {
  display: block;
  width: 100%;
  height: 40px;
  margin: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  white-space: no-wrap;
  vertical-align: baseline;
  cursor: pointer;
  border: 1px solid #d3a928;
  background: #d3a928;
  box-sizing: border-box;
  transition: all 0.4s ease;
}
body.authenticate form input[type=submit]:hover, body.authenticate form input[type=submit]:focus {
  background: gray;
  border: 1px solid gray;
}
body.authenticate form .version {
  display: block;
  font-size: 12px;
  text-align: center;
  opacity: 0.6;
  padding: 45px 0;
}
@media only screen and (min-width: 840px) {
  body.authenticate form .version {
    bottom: 20px;
  }
}
body.authenticate form .version a {
  color: #777;
  text-decoration: none;
}